.mi-container {
    display: flex;
    height: 90vh;
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f3f0f0;
    margin-top: 70px;
  }
  
  .mi-sidebar {
    width: 450px;
    background-color: #fff;
    border-right: 1px solid #ddd;
    padding: 20px;
  }
  
  .mi-sidebar-title {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .mi-sidebar-text {
    margin: 0;
    color: #666;
  }
  
  .mi-search-bar {
    display: flex;
    margin-top: 20px;
  }
  
  .mi-search-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
  }
  
  .mi-search-button {
    padding: 10px;
    border: 1px solid #ddd;
    border-left: 0;
    background-color: #fff;
    border-radius: 0 4px 4px 0;
  }
  
  .mi-message-list {
    margin-top: 20px;
  }
  
  .mi-message-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .mi-profile-pic {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .mi-message-info {
    flex: 1;
  }
  
  .mi-message-sender {
    font-size: 16px;
    margin: 0;
  }
  
  .mi-message-preview {
    margin: 0;
    color: #666;
  }
  
  .mi-message-time {
    color: #666;
    font-size: 12px;
  }
  
  .mi-content {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .mi-message-header {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
  }
  
  .mi-message-body {
    margin-top: 20px;
    background-color: #ccc6c6;
    padding: 10px 15px 0px 15px;
    border-radius: 18px;
    color: rgb(16, 16, 16);
    display: inline-block;
    max-width:fit-content;
    word-wrap: break-word;
    white-space: pre-wrap;
    margin-bottom: 8px;
    position: relative;

  }
  .mi-message-body p{
   margin-top: 5px;
  }
  .doimau{
    background-color: #0066cc;
    margin-left: auto;  
  }



 
  .mi-message-footer {
    margin-top: auto;
    border-top: 1px solid #ddd;
            padding-top: 10px;
            /* margin-bottom: 100px; */
  }
  
  .mi-message-actions {
    display: flex;
    align-items: center;
  }
  
  .mi-action-button {
    background-color: #fff;
    border: 1px solid #ddd;
    padding: 10px;
    margin-right: 10px;
    border-radius: 4px;
  }
  
  .mi-message-input {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .mi-input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .mi-send-button {
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 4px;
    margin-left: 10px;
  }
  
  .mi-message-links {
    margin-top: 10px;
  }
  
  .mi-link {
    display: block;
    color: #0066cc;
    text-decoration: none;
    margin-bottom: 5px;
  }
  
  .mi-link:hover {
    text-decoration: underline;
  }