
.design-register {
  background-image: url('https://i.pinimg.com/originals/81/df/3f/81df3f704ba55281ead0042466b293fa.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-container {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  width: 100%;
  backdrop-filter: blur(10px);
}

.register-container h2 {
  color: white;
  text-align: center;
  margin-bottom: 1.5rem;
}

.register-container .form-group {
  margin-bottom: 1rem;
  text-align: left;
}

.register-container label {
  color: white;
  display: block;
  margin-bottom: 0.5rem;
}

.register-container input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: none;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
}

.register-container button[type="submit"] {
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.register-container button[type="submit"]:hover {
  background-color: #0056b3;
}

.register-container .login-link {
  display: block;
  text-align: center;
  margin-top: 1rem;
  color: white;
  text-decoration: none;
}

.register-container .login-link:hover {
  text-decoration: underline;
}
