.cart-container {
  /* background-color: #1c1c1c; */
  color: #2D2F31;
  
  border-radius: 5px;
  /* margin-top: 20px; */
  display: flex;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}
.tieude{
  /* padding-left: 30px; */
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  color: #2D2F31;
  font-size: 15px;
}
.tieude p{
 
  padding-top: 15px;

}
.cart-header {
  font-size: 24px;
  margin-bottom: 20px;
  padding: 100px 20px 20px 20px;
}



.cart-item {
  display: flex;
  /* justify-content: space-between; */
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  /* padding: 10px; */
  /* border-radius: 5px; */
  /* background-color: #c69ac6; */
  padding-top: 20px;
  border-top: 0.5px black solid;
  width: 900px;

}

.cart-item img {
  width: 100px;
  height: 107px;
  object-fit: cover;
  /* margin-right: 20px; */
  margin-right: 30px;
}

.cart-item-details {
  /* flex-grow: 1; */
  display: flex;
  flex-direction: column;
  
}

.cart-item-title {
  font-size: 18px;
  font-weight: bold;
}

.cart-item-instructor {
  font-size: 14px;
}

.cart-item-rating {
  color: #ffc107;
  font-size: 14px;
}

.cart-item-price {
  font-size: 17px;
  color: #ff6600;
  /* padding-left: 50px;
  padding-bottom: 80px; */
  margin-left: auto;
}

.cart-summary {
  padding: 10px;
  /* background-color: #2a2a2a; */
  border-radius: 5px;
  margin-left: 20px;
  width: 500px;
  margin-left: auto;
  margin-top:  -55px;
  /* background-color: #ffc107; */
}

.cart-summary-total {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.cart-summary-discount {
  color: #ffc107;
  margin-bottom: 10px;
}

.cart-summary-button {
  /* background-color: #800080; */
  color:aliceblue;
  /* font-weight: bold; */
  width: 250px;
  /* padding: 10px; */
background-color: rgb(51, 131, 211);
  
  transition: border 0.4s ease; /* Thêm hiệu ứng mượt khi hover */

}
.cart-summary-button:hover{
   border-radius: 5px; 
  background-color: #ff6600;
  border: 1px solid;
  border-image-slice: 1; /* Bắt buộc phải có để sử dụng border-image */
  border-image-source: linear-gradient(45deg, red, orange, yellow, green, blue, indigo, violet); /* Viền 7 màu */
}


.cart-summary-coupon {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  margin-top: 20px;
 border-top: 0.5px black solid;
 
 padding-top: 30px;
}

.cart-summary-coupon input {
  width: 35%;
  padding: 12px;
  border-color: black;
  font-size: 15px;
}

.cart-summary-coupon button {
  /* color: white;
  font-weight: bold;
 width: fit-content;
  border: none; */
  background-color: white;
  color: #2D2F31;
  font-size: 15px;
  padding:12px;
  margin-bottom: 10px;
  border: 0.5px black solid;
}
.cart-summary-coupon .cart-summary-buttons{
  background-color: cornflowerblue;
  margin-left: 20px;
  color: aliceblue;
}
.cart-summary-coupon .cart-summary-buttons:hover{
   
    background-color: rgb(55, 121, 243);
}

.fontchu {
  font-size: 15px;
}
