.learningPage-container {
    /* padding: 30px; */
    margin-top: 70px; /* Thêm khoảng cách bằng với chiều cao của header */
    font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    padding-left: 30px;
  }
  
  .learningPage-header {
    background-color: #333;
    color: white;
    padding: 20px 50px 5px 50px; /* top, right, bottom, left */

  }
  
  .learningPage-header h1 {
    margin: 0;
    color: aliceblue;
    padding-bottom: 20px;
  }
  
  .learningPage-navBar ul {
    display: flex;
    list-style-type: none;
    padding: 0;
  }
  
  .learningPage-navBar li {
    margin-right: 20px;
    color: white;
  }
  .learningPage-navBar li:hover{
    border-bottom: 5px solid white;
    padding-bottom: 0;
    cursor:pointer;
  }
  .learningPage-scheduleLearning {
    margin: 20px 0;
    width: 1000px;
  }
  
  .learningPage-scheduleBox {
    border: 1px solid #ccc;
    padding: 20px;
  }
  
  .learningPage-scheduleBox h2 {
    margin-top: 0;
  }
  
  .learningPage-actionButtons {
    margin-top: 10px;
  }
  
  .learningPage-startButton,
  .learningPage-cancelButton {
    padding: 10px;
    margin-right: 10px;
    border: none;
    cursor: pointer;
  }
  
  .learningPage-startButton {
    background-color: #333;
    color: white;
  }

  
  .learningPage-cancelButton {
    background-color: #ccc;
    color: black;
  }
  
  .learningPage-courseSection {
    margin-top: 20px;
    width: 250px;
  }
  
  .learningPage-courseBox {
    display:block;
    /* border: 1px solid #ccc;
    padding: 20px; */
  }
  
  .learningPage-courseLogo {
    width: 250px;
    height: 150px;
    margin-right: 20px;
  }
  
  .learningPage-courseInfo h4 {
    margin-top: 5px;
  }
  
  .learningPage-startCourseButton {
    padding: 10px;
    background-color: #333;
    color: white;
    border: none;   
    cursor: pointer;
    margin-top: 10px;
  }
  .learningPage-startCourseButton:hover{
    background-color: #00b2FF;

  }
  .line-learning {
         border: 0.5px solid #999797;
         margin-bottom: 20px;
  }
  