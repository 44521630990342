/* Định nghĩa cấu trúc tổng thể */
.custom-course-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100vh;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

/* Định nghĩa cho phần preview bên trái (70%) */
.custom-course-preview {
  width: 70%;
  /* padding: 20px; */
  /* background-color: #934e4e; */
  /* display: flex; */
  justify-content: center;
  align-items: flex-start;
}
.custom-course-preview p{
  font-size: 24px;
  color:#2D2F31;
  padding: 20px;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
/* Định nghĩa cho phần video player */
.custom-course-preview .plyr-react {
  width: 100%;
   height: 450px;
  padding-right: 50px;
  padding-left: 50px;
}
.settingvideo{
  width: 100%;

}
.custom-course-preview h1 {
  color: #000;
}

/* Định nghĩa cho phần content bên phải (30%) */
.custom-course-content {
  width: 30%;
  /* padding: 20px; */
  background-color: #ffffff;
  border-left: 1px solid #ddd;
}

.custom-course-content h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.custom-section {
  margin-bottom: 20px;
}

.custom-section h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.custom-section ul {
  list-style-type: none;
  padding: 0;
}

.custom-section ul li {
  font-size: 14px;
  margin-bottom: 8px;
}




.course-section {
  border: 1px solid #ccc;
  padding: 10px;
  background-color: white;
  border-radius: 5px;
  cursor: pointer; /* Để hiển thị dấu bàn tay */
 
}

.title {
  color: #2D2F31;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.sub-title {
  font-size: 15px;
  color: #282727;
  margin-bottom: 10px;
}

.lesson-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.lesson-item {
  display: flex;
  align-items:flex-start;
  justify-content:flex-start;
  /* margin-bottom: 10px; */
  /* border: #000 1px solid; */
  /* padding: 15px; */
}
.lesson-item:hover{
  background-color: #9c9898;
} 
.checkbox-container {
  /* width: 30px; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 20px;
}

.content-container {
  /* flex-grow: 1; */
  /* display: flex; */
  /* justify-content:space-between; */
  /* align-items: center; */
  /* border: #000 1px solid; */
 
}


.lesson-title {
  /* flex-grow: 1; */
  margin-top: 2px;
  font-size: 15px;
  color: #2D2F31;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.lesson-time {
  font-size: 12px;
  color:#2D2F31;
  /* margin-left: 10px; */
  padding: 0;
  margin-top: -10px;
  font-family: Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

}

.resource-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 3px 8px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 3px;
}

.resource-button:hover {
  background-color: #e0e0e0;
}
.header-content{
  color: #000;
  font-size: 20px;
  /* border-bottom: #000 1px solid; */
  padding: 15px;
 margin-bottom: -0px;
 font-family: 'Times New Roman', Times, serif;
 margin-right: auto;
}
.header-contents{
  display: flex;
}
.cancel-content{
  font-size: 25px;
  margin-top: 13px;
  margin-right: 15px;
}
