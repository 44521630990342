/* CourseFeedback.css */

.cfb-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: #fff;
}

.cfb-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.cfb-header a {
    text-decoration: none;
    color: #333;
    margin: 0 10px;
}

.cfb-header a.cfb-active {
    border-bottom: 2px solid #000;
}

.cfb-feedback {
    margin-top: 20px;
}

.cfb-feedback h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.cfb-rating-overview {
    display: flex;
    align-items: center;
}

.cfb-rating-overview .cfb-rating {
    font-size: 48px;
    color: #d4a017;
    margin-right: 20px;
}

.cfb-rating-overview .cfb-stars {
    color: #d4a017;
}

.cfb-rating-overview .cfb-text {
    font-size: 16px;
    color: #d4a017;
}

.cfb-rating-bars {
    margin-top: 20px;
}

.cfb-rating-bars .cfb-bar {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

.cfb-rating-bars .cfb-bar .cfb-label {
    width: 50px;
    text-align: right;
    margin-right: 10px;
}

.cfb-rating-bars .cfb-bar .cfb-progress {
    width: 200px;
    height: 10px;
    background-color: #ddd;
    margin-right: 10px;
    position: relative;
}

.cfb-rating-bars .cfb-bar .cfb-progress .cfb-fill {
    height: 100%;
    background-color: #d4a017;
    position: absolute;
    top: 0;
    left: 0;
}

.cfb-rating-bars .cfb-bar .cfb-percentage {
    color: #6a1b9a;
}

.cfb-reviews {
    margin-top: 40px;
}

.cfb-reviews h3 {
    font-size: 20px;
    margin-bottom: 10px;
}

.cfb-search-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.cfb-search-bar input {
    width: 300px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px 0 0 4px;
}

.cfb-search-bar button {
    padding: 10px;
    border: 1px solid #ddd;
    border-left: none;
    background-color: #333;
    color: #fff;
    border-radius: 0 4px 4px 0;
    cursor: pointer;
}

.cfb-filter {
    margin-left: 20px;
}

.cfb-filter select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.cfb-review-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.cfb-review-item .cfb-avatar {
    width: 50px;
    height: 50px;
    background-color: #ddd;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    color: #fff;
    margin-right: 20px;
}

.cfb-review-item .cfb-content {
    flex: 1;
}

.cfb-review-item .cfb-content .cfb-name {
    font-weight: bold;
    margin-bottom: 5px;
}

.cfb-review-item .cfb-content .cfb-time {
    color: #888;
    margin-bottom: 10px;
}

.cfb-review-item .cfb-content .cfb-text {
    margin-bottom: 10px;
}

.cfb-review-item .cfb-content .cfb-actions {
    display: flex;
    align-items: center;
}

.cfb-review-item .cfb-content .cfb-actions button {
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
    margin-right: 10px;
}

.cfb-review-item .cfb-content .cfb-actions button:hover {
    color: #333;
}
.cfb-wrting{
    border: #333 1px solid;
    padding: 10px;
    margin-left: 15px;
    color: black;
}
.cfb-wrting:hover{
    background-color: #cfcaca;
    cursor: pointer;
}